var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":{name:'AssociationAttachmentsShow', params: { id: _vm.$route.params.id }}}},[_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"kind":"secondary"}},[_vm._v(" Voltar para a Associação ")])],1),_c('div',{staticClass:"flex justify-center my-8"},[_c('ValidationObserver',{staticClass:"w-full px-3 lg:w-1/2 xl:w-1/4 list-mt-6",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',{staticClass:"text-3xl mb-3"},[_vm._v(" Editar Associação ")]),_c('form',{staticClass:"grid gap-4",on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('ValidationProvider',{staticClass:"pt-4",attrs:{"name":"service name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome do Serviço","invalid-message":errors[0]},model:{value:(_vm.form.serviceName),callback:function ($$v) {_vm.$set(_vm.form, "serviceName", $$v)},expression:"form.serviceName"}})]}}],null,true)}),_c('DateInput',{attrs:{"label":"Ano da Especialização","max-date":_vm.moment().format('YYYY-MM-DD HH:mm:ss')},model:{value:(_vm.form.startedAt),callback:function ($$v) {_vm.$set(_vm.form, "startedAt", $$v)},expression:"form.startedAt"}}),_c('cv-text-area',{attrs:{"label":"Descrição","rows":"5"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}}),_c('PasswordConfirmField',{staticClass:"lg:w-1/2",attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),_c('div',{staticClass:"mt-4"},[(_vm.done)?_c('cv-button',{attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }