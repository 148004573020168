




















































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import axios from 'axios'

import DateInput from '@/partials/forms/components/DateTime/Date.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    DateInput,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class AssociationEdit extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  form: Record<string, any> = {
    serviceName: '',
    startedAt: moment().format('YYYY-MM-DD 12:00:00'),
    notes: '',
    confirm: '',
    errors: {
      serviceName: '',
      startedAt: '',
      notes: '',
      confirm: ''
    }
  }

  done = true

  created () {
    axios.get(`/user/association/${this.$route.params.id}`)
      .then(response => {
        const { serviceName, startedAt, notes } = camelCaseKeys(response.data.data, { deep: true })
        this.form = { ...this.form, serviceName, startedAt, notes }
      })
  }

  submit () {
    this.done = false

    axios.patch(`/user/association/${this.$route.params.id}/update`, snakeCaseKeys(this.form, { deep: true }))
      .then(response => {
        const flashMessage: flashMessage = {
          message: response.data.data,
          type: 'success'
        }

        this.$router.push({ name: 'AssociationAttachmentsShow', params: { id: this.$route.params.id } }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = camelCaseKeys(err.response, { deep: true })

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      serviceName: '',
      startedAt: '',
      notes: '',
      confirm: ''
    }
  }
}
